import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';

import styles from '../css/page-template.module.css';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import StyledHero from '../components/StyledHero';
import Banner from '../components/Banner';
import TextBlock from '../components/TextBlock';

const PageTemplate = ({ data }) => {
  const { page_title, header, content } = data.markdownRemark.frontmatter;

  const hasImages = () => {
    const filtered = content.filter(item => item.image);
    return filtered.length > 0 ? true : false;
  };

  const renderTitle = ({ title }) => {
    if (title) {
      return <h1 id={title.replace(/\s/g, '-').toLowerCase()}>{title}</h1>;
    }
  };

  const renderTextContent = () => {
    if (content) {
      return content.map((textContent, idx) => {
        return (
          <React.Fragment key={idx}>
            {renderTitle(textContent)}
            <article className={styles.textBox}>
              <TextBlock content={textContent} hasImages={hasImages()} />
            </article>
          </React.Fragment>
        );
      });
    }
    return null;
  };

  const renderTextOnly = () => {
    return (
      <div className={styles.center}>
        <div className={styles.textOnly}>{renderTextContent()}</div>
      </div>
    );
  };

  const renderImages = () => {
    return content.map((item, idx) => {
      if (item.image) {
        return (
          <div key={idx} className={styles.imgBox}>
            <Image fluid={item.image.childImageSharp.fluid} title={item.alt} />
          </div>
        );
      }
      return '';
    });
  };

  const renderTextAndImages = () => {
    return (
      <React.Fragment>
        <div className={styles.textContent}>{renderTextContent()}</div>
        <div className={styles.imageContent}>{renderImages()}</div>
      </React.Fragment>
    );
  };

  return (
    <Layout>
      <SEO title={page_title} />
      <StyledHero img={header.image.childImageSharp.fluid}>
        <Banner title={header.banner_main} info={header.banner_sub} />
      </StyledHero>
      <div className={styles.center}>
        <section className={styles.contentBox}>
          {hasImages() ? renderTextAndImages() : renderTextOnly()}
        </section>
      </div>
    </Layout>
  );
};

export default PageTemplate;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        page_title
        header {
          image {
            childImageSharp {
              fluid(quality: 50, maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          banner_main
          banner_sub
          cta_1 {
            text
            url
          }
          cta_2 {
            text
            url
          }
        }
        cards {
          card {
            image {
              childImageSharp {
                fluid(quality: 90, maxWidth: 280) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            cta {
              text
              url
            }
          }
        }
        content {
          title
          subtitle
          text
          cta {
            text
            url
          }
          alt
          image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

// export const query = graphql`
//   query($slug: String!) {
//     page: contentfulSivu(slug: { eq: $slug }) {
//       name
//       bannerMain
//       bannerSub
//       heading
//       heroImage {
//         fluid(quality: 80, maxWidth: 4160) {
//           ...GatsbyContentfulFluid_withWebp
//         }
//       }
//       pageContent {
//         id
//         name
//         heading
//         text {
//           text
//         }
//         image {
//           title
//           fluid(maxWidth: 300) {
//             ...GatsbyContentfulFluid_withWebp
//           }
//         }
//       }
//     }
//   }
// `;
